/**
 * 系统常用方法
 */
import verify from "@/utils/verifyUtils";

export default {

    /**
     * 是否为开发坏境
     * @returns {string}
     */
    getApiUrl() {
        return process.env.VUE_APP_BASE_API;
    },

    /**
     * 设置本地缓存
     * @param key 键
     * @param val 值
     */
    setLocalStorage(key, val) {
        if(val) {
            localStorage.setItem("weiMaoAgent." + key, val);
        }else{
            localStorage.setItem("weiMaoAgent." + key, "");
        }
    },

    /**
     * 取得本地缓存
     * @param key 键
     * @returns {*}
     */
    getLocalStorage(key) {
        return localStorage.getItem("weiMaoAgent." + key);
    },

    /**
     * 删除本地缓存
     * @param key 键
     * @returns {*}
     */
    delLocalStorage(key) {
        return localStorage.removeItem("weiMaoAgent." + key);
    },

    /**
     * 删除本地缓存
     * @returns {*}
     */
    clearLocalStorage() {
        for (let key in localStorage) {
            if (key.search("weiMaoAgent.") === 0) {
                this.delLocalStorage(key);
            }
        }
    },

    /**
     * 设置token缓存
     * @param val 值
     */
    setToken(val) {
        this.setLocalStorage("userToken", verify.encrypt(val));
    },

    /**
     * 取得token缓存
     * @returns {*}
     */
    getToken() {
        let token = this.getLocalStorage("userToken");
        if (token) {
            return verify.decrypt(token);
        } else {
            return null;
        }
    },

    /**
     * 取得客户端IP
     * @returns {*}
     */
    getClientIp() {
        try {
            return window.returnCitySN.cip
        } catch (e) {
            return "127.0.0.1"
        }
    },

    /**
     * 取得客户端地址
     * @returns {*}
     */
    getClientAddress() {
        try {
            return window.returnCitySN.cname
        } catch (e) {
            return ""
        }
    },

    /**
     * 取得页面宽度
     * @returns {*}
     */
    getClientWidth() {
        return document.body.clientWidth;
    },

    /**
     * 取得页面高度
     * @returns {*}
     */
    getClientHeight() {
        return document.body.clientHeight;
    },

    /**
     * 取得页面高度
     * @returns {*}
     */
    getTableHeight(val) {
        if(this.getClientHeight()>=910){
            return ((this.getClientHeight() - val) + "px");
        }else{
            return ((this.getClientHeight() - (this.getClientHeight() / 880 * val)) + "px");
        }
    },

    /**
     * 设置页面标题
     * @param title 键
     * @returns {*}
     */
    setTitle(title) {
        return (document.title = title);
    },

    /**
     * 页面跳转
     * @param self
     * @param url
     */
    goto(self,url) {
        self.$router.push(url);
    },

    /**
     * 刷新页面
     * @returns {*}
     */
    refresh() {
        location.reload();
    },

    /**
     * 页面跳转
     * @returns {*}
     */
    error(message) {
        this.$message.error(message);
    },

    /**
     * 格式化文件大小
     * @param value
     * @returns {string|*}
     */
    renderSize(value){
        if(isNaN(value) || value === null && value ===""){
            return "0 B";
        }

        try {
            let unitArr = ["B", "K", "M", "G", "T"];
            let index = Math.floor(Math.log(parseFloat(value)) / Math.log(1024));
            let size = (parseFloat(value) / Math.pow(1024, index)).toFixed(2);
            return size + unitArr[index];
        }catch (e){
            return "0 B";
        }
    },

    /**
     * 生成uuid
     */
    getGuid(){
        let S4 = ()=>{
            return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        }
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    },

    /**
     * 取得视频文件长度
     * @returns {string|null}
     */
    getVideoDuration(videoFileObj){
        try {
            let duration = parseFloat(videoFileObj.duration);
            if(!isNaN(duration)) {
                let hour = parseInt(duration / 3600);
                let minute = parseInt((duration % 3600) / 60);
                let second = Math.ceil(duration % 60);
                if (hour < 10) hour = "0" + hour;
                if (minute < 10) minute = "0" + minute;
                if (second < 10) second = "0" + second;
                return (hour + ":" + minute + ":" + second);
            }
            return null;
        }catch (e){
            return null;
        }
    },

    /**
     * 解析网络地址信息
     * @returns {array|null}
     */
    getUrlInfo(url){
        if(url) {
            let urlInfo = new URL(url);
            if (urlInfo) {
                urlInfo.fileExt = (urlInfo.pathname.substr(urlInfo.pathname.lastIndexOf(".") + 1));
                urlInfo.fileName = (urlInfo.pathname.substr(urlInfo.pathname.lastIndexOf("/") + 1));
                return urlInfo;
            }
        }

        return null;
    },

    /**
     * 判断是否存在功能权限
     * @returns boolean
     */
    getRoleFunc(funcId){
        let userFunc = this.base64Decode(this.getLocalStorage("userFunc"));
        let userFuncArray = userFunc.split(",");
        if(userFuncArray.indexOf(funcId+"") > -1){
            return true;
        }

        return false;
    },

    /**
     * 加密
     * @param str
     * @returns {*}
     */
    base64Encode (str) {
        let Base64 = require('js-base64').Base64
        return Base64.encode(str);
    },

    /**
     * 解密
     * @param str
     * @returns {*}
     */
    base64Decode (str) {
        let Base64 = require('js-base64').Base64
        return Base64.decode(str);
    }
}
