import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import './utils/remUtils'
import Moment from 'moment'
import * as utils from './utils/debounceUtils';
import XLSX from 'xlsx'
import 'default-passive-events'
import "./utils/px2rem"
import Print from 'vue3-print-nb'

const app = createApp(App)
app.config.globalProperties.$print = Print
app.config.globalProperties.$moment = Moment
app.config.globalProperties.$xlsx = XLSX
app.config.globalProperties.$utils = utils
installElementPlus(app)
app.use(store).use(router).mount('#app')

export default app
