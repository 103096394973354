/**
 * 数据加解密
 */
export default {
    /**
     * 数据加密
     * @param data
     * @returns {*}
     */
    encrypt(data) {
        return data;
    },

    /**
     * 数据解密
     * @param data
     * @returns {*}
     */
    decrypt(data) {
        return data;
    },
};